import { LeaderboardPrizeSelectionType, TheLeaderboardSourceKeys } from "interfaces";
import React from "react";
import styled from "styled-components";

import CoinsBG from "/public/images/leaderboard/LeaderboardWinnerBlock/wrewards/animated.svg";
import GamdomCoinsBG from "/public/images/leaderboard/LeaderboardWinnerBlock/gamdom/animated.svg";
import PackdrawCoinsBG from "/public/images/leaderboard/LeaderboardWinnerBlock/packdraw/animated.svg";
import ClashItemsBG from "/public/images/leaderboard/LeaderboardWinnerBlock/clash/animated.svg";
import CasesItemsBG from "/public/images/leaderboard/LeaderboardWinnerBlock/cases/animated.svg";
import RainBG from "/public/images/leaderboard/LeaderboardWinnerBlock/raingg/animated.svg";
import RafflesBG from "/public/images/leaderboard/LeaderboardWinnerBlock/bg-raffle-tickets.svg";

import { css } from "styled-components";

export const casinoProviderBackgrounds = {
  [TheLeaderboardSourceKeys.gamdom]: css`
    background: linear-gradient(180deg, rgba(64, 193, 98, 0.25) -82.5%, rgba(0, 114, 126, 0) 65%),
      url("/images/leaderboard/LeaderboardWinnerBlock/gamdom/splash.svg");
    background-size: 100% auto;
    /* @media (min-width: 3200px) {
      background-size: cover;
    } */
  `,
  [TheLeaderboardSourceKeys.hypedrop]: css`
    background: linear-gradient(
        180deg,
        rgba(9, 12, 23, 0.2) 1.84%,
        rgba(48, 71, 106, 0.2) 91.27%,
        rgba(19, 24, 40, 0.2) 139.18%,
        rgba(19, 24, 40, 0.2) 139.18%
      ),
      url("/images/leaderboard/LeaderboardWinnerBlock/bg-hypedrop.svg");
    background-size: 100% auto;
  `,
  [TheLeaderboardSourceKeys.packdraw]: css`
    background: url("/images/leaderboard/LeaderboardWinnerBlock/packdraw/splash-2.png"),
      url("/images/leaderboard/LeaderboardWinnerBlock/packdraw/splash.svg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    @media (max-width: 900px) {
      background-size: contain;
    }
  `,
  [TheLeaderboardSourceKeys.wrewards]: css`
    background-size: 1920px;
    background-position: top center;
    background: url("/images/leaderboard/LeaderboardWinnerBlock/bg-wrewards-3.png"),
      url("/images/leaderboard/LeaderboardWinnerBlock/wrewards/wrewards-splash-new.svg");

    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 1000px;
      filter: blur(200px);
      background: radial-gradient(
        circle,
        rgba(195, 195, 195, 0.94) 0%,
        rgba(0, 178, 255, 0.25) 0%,
        rgba(0, 178, 255, 0.05) 20%,
        rgba(0, 178, 255, 0) 100%
      );
      background-position: top center;
    }
  `,
  [TheLeaderboardSourceKeys.clash]: css`
    background: linear-gradient(180deg, rgba(255, 168, 0, 0.12) 0%, rgba(255, 168, 0, 0) 100%),
      url("/images/leaderboard/LeaderboardWinnerBlock/clash/splash-2.png");
    background-size: cover;
    /* z-index: -1; */
  `,
  [TheLeaderboardSourceKeys.rain_gg]: css`
    background: url("/images/leaderboard/LeaderboardWinnerBlock/raingg/splash.svg"),
      url("/images/leaderboard/LeaderboardWinnerBlock/raingg/splash-2.svg");
    background-size: 100% 600px;
    /* background-position: top center; */
    /* z-index: -1; */
    background-repeat: no-repeat;
  `,
  [TheLeaderboardSourceKeys.cases]: css`
    background: url("/images/leaderboard/LeaderboardWinnerBlock/cases/splash.svg");
    /* background-position: top center; */
    /* z-index: -1; */
    &::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 1000px;
      filter: blur(200px);
      background: radial-gradient(
        circle,
        rgba(195, 195, 195, 0.94) 0%,
        rgba(0, 178, 255, 0.25) 0%,
        rgba(0, 178, 255, 0.05) 20%,
        rgba(0, 178, 255, 0) 100%
      );
      background-position: top center;
    }
  `,
};

const LeaderboardProviderBG: React.FC<{ casinoProvider: string; type?: string }> = ({
  casinoProvider,
  type,
}) => {
  switch (casinoProvider) {
    case TheLeaderboardSourceKeys.gamdom:
      return (
        <AnimationWrapper casinoProvider={casinoProvider}>
          <GamdomCoinsBG />
          {type === LeaderboardPrizeSelectionType.RAFFLE ? (
            <RafflesBG style={{ transform: "translateY(100%)" }} />
          ) : null}
        </AnimationWrapper>
      );
    case TheLeaderboardSourceKeys.packdraw:
      return (
        <AnimationWrapper casinoProvider={casinoProvider}>
          <PackdrawCoinsBG />
          {type === LeaderboardPrizeSelectionType.RAFFLE ? (
            <RafflesBG style={{ transform: "translateY(100%)" }} />
          ) : null}
        </AnimationWrapper>
      );
    case TheLeaderboardSourceKeys.wrewards:
      return (
        <AnimationWrapper casinoProvider={casinoProvider}>
          <CoinsBG />
          {type === LeaderboardPrizeSelectionType.RAFFLE ? (
            <RafflesBG style={{ transform: "translateY(100%)" }} />
          ) : null}
        </AnimationWrapper>
      );
    case TheLeaderboardSourceKeys.clash:
      return (
        <AnimationWrapper casinoProvider={casinoProvider}>
          <ClashItemsBG />
          {type === LeaderboardPrizeSelectionType.RAFFLE ? (
            <RafflesBG style={{ transform: "translateY(100%)" }} />
          ) : null}
        </AnimationWrapper>
      );
    case TheLeaderboardSourceKeys.cases:
      return (
        <AnimationWrapper casinoProvider={casinoProvider}>
          <CasesItemsBG />
          {type === LeaderboardPrizeSelectionType.RAFFLE ? (
            <RafflesBG style={{ transform: "translateY(100%)" }} />
          ) : null}
        </AnimationWrapper>
      );
    case TheLeaderboardSourceKeys.rain_gg:
      return (
        <AnimationWrapper casinoProvider={casinoProvider}>
          <RainBG />
          {type === LeaderboardPrizeSelectionType.RAFFLE ? (
            <RafflesBG style={{ transform: "translateY(100%)" }} />
          ) : null}
        </AnimationWrapper>
      );

    default:
      return (
        <AnimationWrapper casinoProvider={casinoProvider}>
          {type === LeaderboardPrizeSelectionType.RAFFLE ? (
            <RafflesBG style={{ transform: "translateY(100%)" }} />
          ) : null}
        </AnimationWrapper>
      );
  }
};

const AnimationWrapper = styled.div<{ casinoProvider?: string }>`
  width: 100%;
  height: auto;
  position: absolute;
  inset: 0;
  /* max-width: 1440px; */
  margin: 0 auto;
  z-index: 0;
  svg {
    position: absolute;
    inset: 0;
    width: 100%;
  }
  ${({ casinoProvider }) => casinoProvider && casinoProviderBackgrounds[casinoProvider]}
  & g {
    z-index: 1;
    animation: 4.3s ease-in-out 0s infinite normal none running float;

    &:nth-child(odd) {
      animation-duration: 3s;
    }
  }

  & g.pulse {
    z-index: 1;
    animation: 4.3s ease-in-out 0s infinite normal none running smoothlyDisappear;

    &:nth-child(odd) {
      animation-duration: 3s;
    }
  }
  /* TODO replace object */
  /* b-r */
  /* & g[data-id="1"] {
    offset-path: path(M169.434628,85.98385 C302.674368,57.724682 515.410679,111.53943 604,179);
    animation: 4s cubic-bezier(0.42, 0, 0.58, 1) 0s infinite normal none running float;
  } */
  /* b-l */
  /* & g[data-id="2"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
  /* m-l */
  /* & g[data-id="3"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
  /* s-r */
  /* & g[data-id="4"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
  /* s-l */
  /* & g[data-id="5"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
  /* m-r */
  /* & g[data-id="6"] {
    animation: 4s ease-in-out 0s infinite normal none running float;
  } */
`;

export default LeaderboardProviderBG;
