import React from "react";
import { LeaderboardWinnersBlock } from "components";
import { IDtoLeaderboard, IDtoLeaderboardUser, TheLeaderboardSourceKeys } from "interfaces";
import { IAppContext, withApp } from "contexts";
import { CasinoProvider } from "./context/CasinoProviderContext";
import useLeaderboardCurrentUser from "../../hooks/leaderboards/useLeaderboardCurrentUser";
import useLeaderboard from "../../hooks/leaderboards/useLeaderboard";
import useLeaderboardUsers from "../../hooks/leaderboards/useLeaderboardUsers";

interface ILeaderboardWinners {
  id?: string;
  nextScollHref?: string;
  switcher?: string;
  showBlockHeader?: boolean;
  showLeaderboardEndsIn?: boolean;
  showLeaderboardUserList?: boolean;
  showIntervalUpdate?: boolean;
  showActiveLeaderboardButton?: boolean;
}

const LeaderboardWinnersScreenPure: React.FC<ILeaderboardWinners & IAppContext> = ({
  id,
  // accessFetch,
  // accessURL,
  showBlockHeader = true,
  showLeaderboardEndsIn = false,
  showLeaderboardUserList = false,
  showIntervalUpdate = true,
  showActiveLeaderboardButton = true,
}): React.ReactElement => {
  const [switcher, setSwitcher] = React.useState<string>(TheLeaderboardSourceKeys.gamdom);

  const { data: leaderboard } = useLeaderboard({ casinoProvider: switcher });
  const { data: {items: leaderboardUsers} } = useLeaderboardUsers({ casinoProvider: switcher });

  const { data: leaderboardLoggedIn } = useLeaderboardCurrentUser(leaderboard?.id);

  const onSetSwitcher = (value: string) => {
    setSwitcher(value);
  };

  return (
    <div id={id}>
      <LeaderboardWinnersBlock
        switcher={switcher}
        setSwitcher={onSetSwitcher}
        leaderboard={leaderboard}
        leaderboardWinners={leaderboardUsers?.slice(0, 3)}
        leaderboardUsers={leaderboardUsers?.slice(3)}
        showBlockHeader={showBlockHeader}
        showLeaderboardEndsIn={showLeaderboardEndsIn}
        showLeaderboardUserList={showLeaderboardUserList}
        showIntervalUpdate={showIntervalUpdate}
        showActiveLeaderboardButton={showActiveLeaderboardButton}
        currentUserPosition={leaderboardLoggedIn}
      />
    </div>
  );
};

export const LeaderboardWinnersScreen = withApp(LeaderboardWinnersScreenPure);
