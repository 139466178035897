import React from "react";
import styled, { css } from "styled-components";
import { Flex, IStyles } from "components/common";
import Link from "next/link";
import { LeaderboardPrizeSelectionType, TheLeaderboardSourceKeys } from "interfaces";
import Image from "next/image";
import TicketG from "/public/images/icons/ticket.svg";
interface LeaderboardHowToPlayProps {
  casinoProvider: string;
  type: LeaderboardPrizeSelectionType;
  ticketRate: number;
}

const Title = styled.h5`
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 48px;
  margin: 0px 0px 80px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 900;
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.55);

  text-align: center;
  color: #e9f4ff;
  /* & b {
    background: linear-gradient(
      88.51deg,
      #2b7ae2 -11.93%,
      #4fc9f4 32.84%,
      #87e9ff 44.22%,
      #4fd1f4 55.23%,
      #2884e0 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
  } */
`;

const Wrapper = styled.div`
  max-width: 1008px;
  /* padding: 0 1rem; */
  margin: 1rem auto;
`;
const GridBox = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem 1rem;
`;

const Box = styled.div<IStyles & { number: number }>`
  min-width: 280px;
  flex: 1;
  padding: 2.5rem 1.5rem;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='6' ry='6' stroke='%233B8BE9FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='23' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 0.375rem;
  background-color: rgba(59, 140, 233, 0.05);
  font-family: var(--font-family-golos);
  position: relative;
  backdrop-filter: blur(10px);
  &::before {
    content: "${({ number }) => number}";
    font-family: var(--font-family-exo);
    font-style: italic;
    font-weight: 900;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: #131620;
    border-radius: 40px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 0px 2px #1d202b;
    border: 2px solid #131620;
  }
`;

const BottomBox = styled.div`
  width: 100%;
  margin: 1rem auto 5rem;
  padding: 1.5rem;
  border: 1px dashed #e9f4ff40;
  border-radius: 0.375rem;
  background-color: rgba(59, 140, 233, 0.05);
  font-family: var(--font-family-golos);
  font-weight: 400;
  font-size: 14px;
  position: relative;
  color: #e9f4ffbf;
  text-align: center;
  backdrop-filter: blur(20px);
`;

const BoxTitle = styled.h6`
  margin: 0 0 1rem;
  font-weight: 600;
  font-size: 1rem;
  color: #3b8be9;
  text-transform: uppercase;
  text-align: center;
`;

const BoxContent = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  color: #e9f4ffbf;
  text-align: center;

  & a {
    text-transform: uppercase;
    color: #3b8be9;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const getDynamicValues = (provider: string) => {
  switch (provider) {
    case TheLeaderboardSourceKeys.hypedrop:
      return {
        wgtvHref: "https://hypedrop.com/r/WGTV",
        watchGamesTVHref: "https://hypedrop.com/r/watchgamestv",
        providerHref: "https://hypedrop.com/",
        providerName: "Hypedrop",
      };
    case TheLeaderboardSourceKeys.gamdom:
      return {
        wgtvHref: "https://gamdom.com/r/wgtv",
        watchGamesTVHref: "https://gamdom.com/r/watchgamestv",
        providerHref: "https://gamdom.com/",
        providerName: "Gamdom",
      };
    case TheLeaderboardSourceKeys.packdraw:
      return {
        wgtvHref: "https://packdraw.com/?ref=wgtv",
        watchGamesTVHref: "https://packdraw.com/?ref=watchgamestv",
        providerHref: "https://packdraw.com/",
        providerName: "PackDraw",
      };
    case TheLeaderboardSourceKeys.clash:
      return {
        wgtvHref: "https://clash.gg/r/WGTV",
        watchGamesTVHref: "https://clash.gg/r/watchgamestv",
        providerHref: "https://clash.gg",
        providerName: "Clash",
      };

    case TheLeaderboardSourceKeys.cases:
      return {
        wgtvHref: "https://cases.gg/r/WGTV",
        watchGamesTVHref: "https://cases.gg/r/watchgamestv",
        providerHref: "https://cases.gg",
        providerName: "Cases",
      };

    case TheLeaderboardSourceKeys.rain_gg:
      return {
        wgtvHref: "https://rain.gg/r/WGTV",
        watchGamesTVHref: null,
        providerHref: "https://rain.gg",
        providerName: "Rain",
      };

    default:
      return {
        wgtvHref: "/",
        watchGamesTVHref: "/",
        providerHref: "/",
        providerName: "WRewards",
      };
  }
};

const LeaderboardHowToPlay: React.FC<LeaderboardHowToPlayProps> = ({
  casinoProvider,
  type,
  ticketRate,
}) => {
  const { watchGamesTVHref, wgtvHref, providerName, providerHref } =
    getDynamicValues(casinoProvider);

  if (type === LeaderboardPrizeSelectionType.RAFFLE) {
    return (
      <Wrapper id="how-to-play">
        <Flex column gap={32} justify="center" align="center">
          <InfoBox>
            <p>
              Use the code{" "}
              <Link target="_blank" href={wgtvHref}>
                WGTV
              </Link>
              {watchGamesTVHref && (
                <>
                  {` or `}
                  <Link target="_blank" href={watchGamesTVHref}>
                    WatchGamesTV
                  </Link>
                </>
              )}{" "}
              , wager and earn tickets for <b>every {ticketRate} $ wagered </b> on{" "}
              <Link target="_blank" href={wgtvHref}>
                {`${providerName}`}
              </Link>
              . <br /> The more you wager, the greater your<b> chances of winning </b>!
            </p>
          </InfoBox>

          <TicketBox>
            <Flex gap={16} align="center">
              <Flex gap={8} align="center">
                <IconBox>
                  <b>$</b>
                </IconBox>{" "}
                {`$ ${ticketRate} Wagered`}
              </Flex>
              <div>=</div>
              <Flex gap={8} align="center">
                <IconBox>
                  <TicketG />
                </IconBox>{" "}
                1 Ticket
              </Flex>
            </Flex>
          </TicketBox>

          <BottomBox style={{ margin: "1rem 0" }}>
            {`Please note, to ensure a fair gaming environment, refrain from misusing ${providerName} reward system. Each user will be manually checked complying with leaderboard rules, and abuse. Wagering on ${providerName} house games (Roulette, Dice.. etc) & games like Baccarat & more are not allowed. If any other abuse is found, on games offered by ${providerName} you will be banned and not paid out.`}
          </BottomBox>
        </Flex>
      </Wrapper>
    );
  }

  return (
    <Wrapper id="how-to-play">
      <Title>
        How it <b>works</b>
      </Title>
      <GridBox>
        <Box number={1}>
          <BoxTitle>
            {casinoProvider === TheLeaderboardSourceKeys.wrewards
              ? "Get points"
              : `${
                  casinoProvider === TheLeaderboardSourceKeys.hypedrop ? "Deposit" : "Wager"
                } Using refferal Code`}
          </BoxTitle>

          {casinoProvider === TheLeaderboardSourceKeys.wrewards ? (
            <BoxContent>
              Gain points by attending the WRewards team streams and exchange them into GPoints to
              play.
            </BoxContent>
          ) : (
            <BoxContent>
              <Link target="_blank" href={wgtvHref}>
                WGTV
              </Link>
              {watchGamesTVHref && (
                <>
                  {` or `}
                  <Link target="_blank" href={watchGamesTVHref}>
                    WatchGamesTV
                  </Link>
                  <br />
                </>
              )}
              {` on `}
              <Link target="_blank" href={wgtvHref}>
                {`${providerName}`}
              </Link>
              .
            </BoxContent>
          )}
        </Box>
        <Box number={2}>
          <BoxTitle>
            {casinoProvider === TheLeaderboardSourceKeys.wrewards
              ? "Provably fair"
              : "Do not hide your profile"}
          </BoxTitle>
          <BoxContent>
            {casinoProvider === TheLeaderboardSourceKeys.wrewards
              ? "All games are 100% provably fair and have an option to verify any round to make sure it was fair."
              : `Users with HIDDEN PROFILES will not be shown on leaderboard and will not earn a prize
            when leaderboard ends`}
          </BoxContent>
        </Box>
        <Box number={3}>
          <BoxTitle>monthly reset</BoxTitle>
          <BoxContent>
            Stay competitive! The Leaderboard is refreshed every{" "}
            {casinoProvider === TheLeaderboardSourceKeys.wrewards ? "10 minutes" : "12 hours"}
          </BoxContent>
        </Box>
      </GridBox>
      <BottomBox>
        {`Please note, to ensure a fair gaming environment, refrain from misusing ${providerName}'s reward
          system. Each user will be manually checked for complying with leaderboard rules, and
          abuse.${
            casinoProvider === TheLeaderboardSourceKeys.gamdom
              ? " Wagering on Gamdom's house games (Roulette, Dice.. etc) & games like Baccarat & more is not allowed. If any other abuse is found, on games offered by Gamdom you will be banned and not paid out."
              : ""
          }`}
      </BottomBox>
    </Wrapper>
  );
};

const InfoBox = styled.div`
  font-size: 1rem;
  font-weight: 500;
  line-height: 21px;
  color: #e9f4ffbf;
  text-align: center;
  b {
    font-size: 1rem;
    font-weight: 500;
    color: #3b8be9;
  }
  & a {
    text-transform: uppercase;
    color: #3b8be9;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const TicketBox = styled.div`
  background: #141722;
  color: #d5e0f5;
  border: 2px solid #1d2234;
  border-radius: 100px;
  padding: 0.5rem;
`;

const IconBox = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: linear-gradient(
      105deg,
      rgba(208, 219, 255, 0) -24.09%,
      rgba(209, 219, 255, 0.02) 47.65%,
      rgba(209, 219, 255, 0.09) 65.58%,
      rgba(215, 224, 255, 0.02) 83.52%,
      rgba(222, 231, 255, 0) 155.26%
    ),
    rgba(49, 56, 81, 0.15);
  backdrop-filter: blur(16px);

  b {
    font-size: 24px;
    font-weight: 800;
    font-family: var(--font-family-exo);
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

export default LeaderboardHowToPlay;
