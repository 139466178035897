import React, { PropsWithChildren } from "react";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { ComponentPropsWithRef, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import Arrow from "/public/images/icons/arrow-left.svg";

type PropType = {
  slides?: React.ReactNode[];
  options?: EmblaOptionsType;
};

const SlideButtonGroup: React.FC<PropType & PropsWithChildren> = (props) => {
  const { slides, options, children } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel({ ...options, align: "center" });

  // const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi);

  return (
    <AbsoluteWrapper>
      <Root className="buttonSlider">
        <div className="buttonSlider__viewport" ref={emblaRef}>
          <div className="buttonSlider__container">{children}</div>
        </div>

        <div className="buttonSlider__controls">
          <div className="buttonSlider__buttons">
            <WrewardsButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
              variant="primary-icon-bordered"
              className="buttonSlider__button"
            >
              <PrevButton />
            </WrewardsButton>
            <WrewardsButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
              variant="primary-icon-bordered"
              className="buttonSlider__button"
            >
              <NextButton />
            </WrewardsButton>
          </div>

          {/* <div className="buttonSlider__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"buttonSlider__dot".concat(
                index === selectedIndex ? " buttonSlider__dot--selected" : ""
              )}
            />
          ))}
        </div> */}
        </div>
      </Root>
    </AbsoluteWrapper>
  );
};

const AbsoluteWrapper = styled.div`
  position: relative;
  padding: 0 20px;
`;
const Root = styled.div`
  --slide-height: 70px;
  --slide-spacing: 1rem;
  --slide-size: 170px;
  width: fit-content;
  flex: 1;
  /* max-width: 1048px; */
  max-width: 940px;
  width: 100%;
  margin: 0 20px;

  @media (max-width: 940px) {
    max-width: 100%;
  }

  &.buttonSlider {
    margin: auto;
    position: relative;
    background-color: #141722;
    border: 2px solid #c8d7f51a;
    border-radius: 60px;
    padding: 4px;
    user-select: none;
    /* overflow: ; */
  }
  .buttonSlider__viewport {
    overflow: hidden;
    position: relative;
    z-index: 1;
    border-radius: 60px;
    padding: 0 24px;
  }
  .buttonSlider__container {
    display: flex;
    touch-action: pan-y pinch-zoom;
    margin-left: calc(var(--slide-spacing) * -1);
    /* overflow: hidden; */
  }
  .buttonSlider__slide {
    transform: translate3d(0, 0, 0);
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
    z-index: 10;
  }

  .buttonSlider__slide__number {
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttonSlider__controls {
    position: absolute;
    inset: 0px -40px;
    height: 100%;
    display: flex;
    overflow: hidden;
  }
  .buttonSlider__buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    align-items: center;
  }
  .buttonSlider__button {
    border-color:  rgba(200, 215, 245, 0.75);
    background: rgba(200, 215, 245, 0.15);
    color: #fff;
    backdrop-filter: blur(100px);

    &:hover {
      color: #00b2ff;
      border-color:  #00b2ff;
      background: rgba(200, 215, 245, 0.15);
    }
  }
  .buttonSlider__button:disabled {
    /* opacity: 0; */
  }
  .buttonSlider__button__svg {
    width: 16px;
    height: 16px;
  }
  .buttonSlider__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
  }
  .buttonSlider__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .buttonSlider__dot:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: "";
  }
  .buttonSlider__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem var(--text-body);
  }
`;

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

type PropType2 = ComponentPropsWithRef<"button">;

export const PrevButton: React.FC = () => {
  return (
    // <button
    //   className="buttonSlider__button buttonSlider__button--prev"
    //   type="button"
    //   {...restProps}
    // >
    <svg className="buttonSlider__button__svg" viewBox="0 0 532 532">
      <path
        fill="currentColor"
        d="M355.66 11.354c13.793-13.805 36.208-13.805 50.001 0 13.785 13.804 13.785 36.238 0 50.034L201.22 266l204.442 204.61c13.785 13.805 13.785 36.239 0 50.044-13.793 13.796-36.208 13.796-50.002 0a5994246.277 5994246.277 0 0 0-229.332-229.454 35.065 35.065 0 0 1-10.326-25.126c0-9.2 3.393-18.26 10.326-25.2C172.192 194.973 332.731 34.31 355.66 11.354Z"
      />
    </svg>
    // </button>
  );
};

export const NextButton: React.FC = () => {
  return (
    <svg className="buttonSlider__button__svg" viewBox="0 0 532 532">
      <path
        fill="currentColor"
        d="M176.34 520.646c-13.793 13.805-36.208 13.805-50.001 0-13.785-13.804-13.785-36.238 0-50.034L330.78 266 126.34 61.391c-13.785-13.805-13.785-36.239 0-50.044 13.793-13.796 36.208-13.796 50.002 0 22.928 22.947 206.395 206.507 229.332 229.454a35.065 35.065 0 0 1 10.326 25.126c0 9.2-3.393 18.26-10.326 25.2-45.865 45.901-206.404 206.564-229.332 229.52Z"
      />
    </svg>
  );
};

// type UseDotButtonType = {
//   selectedIndex: number;
//   scrollSnaps: number[];
//   onDotButtonClick: (index: number) => void;
// };

// export const useDotButton = (emblaApi: EmblaCarouselType | undefined): UseDotButtonType => {
//   const [selectedIndex, setSelectedIndex] = useState(0);
//   const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

//   const onDotButtonClick = useCallback(
//     (index: number) => {
//       if (!emblaApi) return;
//       emblaApi.scrollTo(index);
//     },
//     [emblaApi]
//   );

//   const onInit = useCallback((emblaApi: EmblaCarouselType) => {
//     setScrollSnaps(emblaApi.scrollSnapList());
//   }, []);

//   const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
//     setSelectedIndex(emblaApi.selectedScrollSnap());
//   }, []);

//   useEffect(() => {
//     if (!emblaApi) return;

//     onInit(emblaApi);
//     onSelect(emblaApi);
//     emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
//   }, [emblaApi, onInit, onSelect]);

//   return {
//     selectedIndex,
//     scrollSnaps,
//     onDotButtonClick,
//   };
// };

// type PropType3 = ComponentPropsWithRef<"button">;

// export const DotButton: React.FC<PropType3> = (props) => {
//   const { children, ...restProps } = props;

//   return (
//     <button type="button" {...restProps}>
//       {children}
//     </button>
//   );
// };

export default SlideButtonGroup;
