import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { NotificationCardProps, NotificationType, NotificationUIModel } from "types/notification";
import RaffleEndImage from "assets/notifications/flames-raffle-end.svg";
import RaffleStartImage from "assets/notifications/stars-raffle-start.svg";
import CalendarDayImage from "assets/notifications/gifts-calendar-day.svg";
import WrewardsShield from "assets/notifications/wrewards-shield.svg";
import CalendarGolden from "assets/notifications/calendar-golden.svg";
import NewGameImage from "assets/notifications/new-game.svg";

import Flame from "assets/notifications/flame.svg";
import Ticket from "assets/notifications/ticket.svg";
import Check from "assets/notifications/check.svg";
import Berry from "assets/notifications/berry.svg";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import { useCallback, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import { SlotBlobUrl } from "types/slots/constants";
import Image from "next/image";

const GenericNotificationCard = (props: NotificationCardProps) => {
  const router = useRouter();
  const { setShowChatSidebar } = useShowPageLayoutContext();
  const { title, type, info, description, contentRenderer, onActionButtonClick, containerStyles } =
    props;
  const [showFallback, setFallback] = useState(false);

  const ImageBgByType = useMemo(() => {
    switch (type) {
      case NotificationType.RaffleEnd:
        return RaffleEndImage();
      case NotificationType.RaffleStart:
        return RaffleStartImage();
      case NotificationType.RaffleRouletteStart:
        return RaffleStartImage();
      case NotificationType.NewCalendarDay:
        return CalendarDayImage();
      case NotificationType.NewInhouseGame:
        return NewGameImage();
      case NotificationType.NewSlot:
        return NewGameImage();
    }
  }, [type]);

  const ImageByType = useMemo(() => {
    switch (type) {
      case NotificationType.RaffleEnd:
      case NotificationType.RaffleStart:
      case NotificationType.RaffleRouletteStart:
        return WrewardsShield();
      case NotificationType.NewCalendarDay:
        return CalendarGolden();
      case NotificationType.NewSlot: {
        const { provider, imageCode } = info;
        const {
          bg: bgImage,
          icon: iconImage,
          text: textImage,
        } = {
          bg: `${SlotBlobUrl}/${provider?.toLowerCase()}/${imageCode}/bg.png`,
          icon: `${SlotBlobUrl}/${provider?.toLowerCase()}/${imageCode}/icon.png`,
          text: `${SlotBlobUrl}/${provider?.toLowerCase()}/${imageCode}/text.png`,
        };
        return (
          <ImageCard>
            <Image
              fill
              alt={info?.imageCode ?? "slot-image"}
              src={bgImage}
              loading="eager"
              className="img-bg"
              quality={30}
              onError={() => setFallback(true)}
              style={
                showFallback
                  ? {
                      opacity: showFallback ? 0 : 1,
                    }
                  : {
                      opacity: showFallback ? 0 : 1,
                    }
              }
            />
            {showFallback ? (
              <>
                <Image
                  fill
                  alt={info?.imageCode ?? "slot-image"}
                  className="img-bg"
                  src={`https://wrewards.s3.eu-north-1.amazonaws.com/mole-smash/mole-smash_bg.png`}
                  loading="eager"
                  sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
                  quality={30}
                  style={{
                    filter: "blur(4px)",
                  }}
                />
                <Image
                  fill
                  alt={info?.imageCode ?? "slot-image"}
                  className="img-icon"
                  style={{ padding: "10px 20px 60px", filter: "blur(2px)" }}
                  src={`/images/blackjack/wrewards-back.svg`}
                  loading="eager"
                  sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
                  quality={80}
                />
              </>
            ) : (
              <>
                <Image
                  fill
                  alt={info?.imageCode ?? "slot-image"}
                  className="img-icon"
                  src={iconImage}
                  loading="eager"
                  sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
                  quality={80}
                />
                <Image
                  fill
                  alt={info?.imageCode ?? "slot-image"}
                  className="img-name"
                  src={textImage}
                  loading="eager"
                  sizes="(max-width: 768px) 140px, (max-width: 1200px) 160px, 180px"
                  quality={20}
                />
              </>
            )}
          </ImageCard>
        );
      }
      case NotificationType.NewInhouseGame: {
        const { houseGameName } = info;
        return (
          <ImageCard>
            <Image
              fill
              alt={houseGameName ?? "inhouse"}
              src={`/images/gamesComponent/${houseGameName}.png`}
              loading="eager"
              className="img-bg"
              quality={30}
            />
          </ImageCard>
        );
      }
    }
  }, [type, info, showFallback]);

  const IconByType = useMemo(() => {
    switch (type) {
      case NotificationType.RaffleEnd:
      case NotificationType.RaffleRouletteStart:
        return Flame();
      case NotificationType.RaffleStart:
        return Ticket();
      case NotificationType.NewCalendarDay:
        return Check();
      case NotificationType.NewInhouseGame:
        return Berry();
      case NotificationType.NewSlot:
        return Berry();
    }
  }, [type]);

  const titleParts = useMemo(() => {
    const parts = title?.split(" ");
    let first = "";
    let second = "";

    switch (type) {
      case NotificationType.RaffleEnd: {
        first = parts ? parts[0] : "";
        second = parts ? parts.slice(1).join(" ") : "";
        break;
      }
      case NotificationType.RaffleStart:
      case NotificationType.RaffleRouletteStart: {
        first = parts ? parts.slice(0, 2).join(" ") : "";
        second = parts ? parts.slice(2).join(" ") : "";
        break;
      }
      default: {
        first = parts ? parts.join(" ") : "";
      }
    }
    return [first, second];
  }, [title, type]);

  const angleLabelByType = useMemo(() => {
    switch (type) {
      case NotificationType.NewCalendarDay:
        return info.angleLabel ?? "Day N";
      case NotificationType.NewInhouseGame:
        return info.angleLabel ?? "New";
      case NotificationType.NewSlot:
        return info.angleLabel ?? "New";
      default:
        return "";
    }
  }, [info.angleLabel, type]);

  const buttonVariantByType = useMemo(() => {
    switch (type) {
      case NotificationType.NewInhouseGame:
      case NotificationType.NewSlot:
        return "game-action-green";
      default:
        return "game-action-blue";
    }
  }, [type]);

  const onActionButtonClickInner = useCallback(() => {
    if (info.onActionClickLink) {
      onActionButtonClick && onActionButtonClick();
      router.push(info.onActionClickLink);
      setShowChatSidebar(false);
    }
  }, [info.onActionClickLink, onActionButtonClick, router, setShowChatSidebar]);

  return (
    <NotificationCardContainer type={type} styles={containerStyles}>
      <NotificationCardSideBgImageContainer>{ImageBgByType}</NotificationCardSideBgImageContainer>

      <NotificationCardAngleInfo type={type}>{angleLabelByType}</NotificationCardAngleInfo>
      <NotificationCardContent>
        <NotificationCardSideImageContainer>{ImageByType}</NotificationCardSideImageContainer>
        <NotificationCardTextContainer>
          <NotificationTitleContainer type={type}>
            <span>{titleParts[0]}</span>
            <span>{titleParts[1]}</span>
          </NotificationTitleContainer>

          <NotificationDescriptionContainer>
            {description}
            {contentRenderer && contentRenderer}
          </NotificationDescriptionContainer>
        </NotificationCardTextContainer>
      </NotificationCardContent>
      {info.onActionClickLink && (
        <WrewardsButton
          onClick={onActionButtonClickInner}
          variant={buttonVariantByType}
          style={{ fontSize: "12px", width: "100%", padding: "8px", zIndex: "2" }}
        >
          {IconByType}
          {info.actionButtonText}
        </WrewardsButton>
      )}
    </NotificationCardContainer>
  );
};
const bgVariants: Record<any, FlattenSimpleInterpolation> = {
  [NotificationType.RaffleEnd]: css`
    background: radial-gradient(
      70.8% 93.57% at 0% 100%,
      rgba(255, 121, 26, 0.45) 0%,
      rgba(238, 42, 45, 0.3375) 34%,
      rgba(255, 0, 123, 0) 170%
    );
  `,
  [NotificationType.RaffleStart]: css`
    background: linear-gradient(
      185.75deg,
      rgba(0, 157, 52, 0)-12.83%,
      rgba(42, 238, 147, 0.47) 173.38%
    );
  `,
  [NotificationType.RaffleRouletteStart]: css`
    background: linear-gradient(
      185.75deg,
      rgba(0, 157, 52, 0)-12.83%,
      rgba(42, 238, 147, 0.47) 173.38%
    );
  `,
  [NotificationType.NewCalendarDay]: css`
    background: linear-gradient(
      240.51deg,
      rgba(255, 119, 0, 0) 0%,
      rgba(215, 135, 22, 0.0875) 6.23%,
      rgba(255, 177, 68, 0.200375) 55.4%,
      rgba(255, 220, 79, 0.35) 98.46%
    );

    box-shadow: -1px 1px 0px 0px rgba(221, 188, 128, 0.705);
  `,
  [NotificationType.NewSlot]: css`
    background: linear-gradient(
      240.51deg,
      rgba(17, 0, 255, 0) 0%,
      rgba(22, 26, 215, 0.0875) 30.23%,
      rgba(220, 22, 200, 0.35) 98.46%
    );
    box-shadow: -0.5px 0.5px 0px 0px #ea00ff;
  `,
  [NotificationType.NewInhouseGame]: css`
    background: linear-gradient(
      240.51deg,
      rgba(17, 0, 255, 0) 0%,
      rgba(22, 26, 215, 0.0875) 30.23%,
      rgba(220, 22, 200, 0.35) 98.46%
    );
    box-shadow: -0.5px 0.5px 0px 0px #ea00ff;
  `,
};

type CardBg = keyof typeof bgVariants;

const NotificationCardContainer = styled.div<{ type: CardBg; styles?: FlattenSimpleInterpolation }>`
  max-height: 230px;
  width: 100%;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  ${({ type }) => bgVariants?.[type]}
  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `}
`;

const NotificationCardContent = styled.div`
  min-height: 130px;
  max-height: 160px;
  width: 100%;
  display: flex;
  position: relative;
  gap: 8px;
  flex-grow: 1;
`;

const NotificationCardSideBgImageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  left: 0;
  top: 0;
  z-index: 0;
  & svg {
    left: 0;
    bottom: 0;
    height: 100%;
    position: absolute;
    transform: rotate(7deg);
  }
`;

const cardAngleVariants: Record<any, FlattenSimpleInterpolation> = {
  [NotificationType.NewCalendarDay]: css`
    background-color: var(--pure-sky);
  `,
  [NotificationType.NewInhouseGame]: css`
    background-color: var(--toxic-color-dark);
  `,
  [NotificationType.NewSlot]: css`
    background-color: var(--toxic-color-dark);
  `,
};

type CardAngleType = keyof typeof cardAngleVariants;

const NotificationCardAngleInfo = styled.div<{ type: CardAngleType }>`
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px;
  font-family: var(--font-family-golos);
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: black;
  border-bottom-left-radius: 8px;
  ${({ type }) => cardAngleVariants?.[type]}
`;

const NotificationCardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: calc(100% - 40px - 64px);
`;

const NotificationCardSideImageContainer = styled.div`
  height: 64px;
  width: 64px;
  display: flex;
  padding-top: 20px;
  & svg {
    position: relative;
    top: 10%;
    height: 64px;
    width: 64px;
  }
`;

const titleVariant: Record<any, FlattenSimpleInterpolation> = {
  [NotificationType.RaffleEnd]: css`
    color: var(--flame);
    margin-left: 5px;
  `,

  [NotificationType.RaffleStart]: css`
    color: var(--toxic-color-dark);
    display: inline-block;
  `,
  [NotificationType.RaffleRouletteStart]: css`
    color: var(--toxic-color-dark);
    display: inline-block;
  `,
};

type TitleCardColor = keyof typeof titleVariant;

const NotificationTitleContainer = styled.div<{ type: TitleCardColor }>`
  font-family: var(--font-family-exo);
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  line-height: 20.8px;
  text-align: left;
  text-transform: uppercase;
  margin-top: 16px;
  & > :nth-child(1) {
    color: var(--daylight-color);
  }
  & > :nth-child(2) {
    ${({ type }) => titleVariant?.[type]}
  }
`;

const NotificationDescriptionContainer = styled.div`
  font-family: var(--font-family-golos) !important;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: var(--daylight-75-color);
  text-overflow: ellipsis;
  max-height: 90px;
  overflow: hidden;
`;

const ImageCard = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 90px;
  .img-icon,
  .img-bg {
    transition: transform 0.3s linear;
    transform: scale(1.01);
    box-shadow: 0px 0px 0px 2px #1e213a inset;
  }
`;

export default GenericNotificationCard;
