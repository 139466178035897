import { memo, useState } from "react";
import styled, { css } from "styled-components";
import CopyIcon from "assets/games/crash/images/copy.svg";

type CopyButtonSize = "sm" | "md" | "lg";
interface CopyButtonComponentProps {
  copyValue: string;
  color?: string;
  size?: CopyButtonSize;
}

const CopyButtonComponent = ({ copyValue, color, size = "md" }: CopyButtonComponentProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const onCopyClick = () => {
    if (!isCopied) {
      navigator.clipboard.writeText(copyValue);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 500);
    }
  };
  return (
    <CopyContainer
      size={size}
      color={color}
      onClick={(e) => {
        e.stopPropagation();
        onCopyClick();
      }}
    >
      {isCopied ? <span> Copied </span> : <CopyIcon />}
    </CopyContainer>
  );
};

const CopyButton = memo(CopyButtonComponent);
export default CopyButton;

const getDimensionsBySize = (size: CopyButtonSize) => {
  switch (size) {
    case "sm":
      return css`
        width: 19px;
        height: 18px;
      `;
    case "md":
      return css`
        width: 25px;
        height: 24px;
      `;
    case "lg":
      return css`
        width: 30px;
        height: 29px;
      `;
    default:
      return css``;
  }
};
const CopyContainer = styled.div<{ color?: string; size: CopyButtonSize }>`
  min-height: 29px;
  min-width: 40px; // Copied text width
  display: flex;
  align-items: center;
  cursor: pointer;
  & svg {
    ${({ size }) => getDimensionsBySize(size)};
  }
  & span {
    color: var(--toxic-color);
  }

  &:active {
    transform: scale(0.95);
  }
  ${({ color }) => (color ? `color:${color}` : "color: transparent")};
  font-family: var(--font-family-exo);
  font-size: 13px;
`;
