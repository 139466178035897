import React from "react";
import styled, { css } from "styled-components";
import { IStyles, $styles } from "components";
import { Flex } from "components/common";
import Image from "next/image";
import { TheFieldset, TheIcon } from "components/TheComponents";
import { ManagePrizesButton } from "./LeaderboardCreateLayout";
import { LeaderboardPrize } from "interfaces";
import { addCommasToNumber } from "helpers/addCommasToNumber";
import { PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT } from "helpers/constants";

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${$styles}
`;

interface LeaderboardTieredPrizesLayoutProps {
  // ?? legacy
  // errors: string[];
  // setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  prizes: string[];
  additionalPrizes: LeaderboardPrize[];
  setPayload: (values: any) => void;
}

const LeaderboardTieredPrizesLayout: React.FC<LeaderboardTieredPrizesLayoutProps> = ({
  additionalPrizes,
  prizes,
  setPayload,
}): React.ReactElement => {
  const numProps = { pattern: "^[d]*$", type: "text" };

  const addAdditionalPrize = () => {
    setPayload((prev: any) => ({
      ...prev,
      additionalPrizes: [
        ...prev.additionalPrizes,
        {
          prizeNumber: prev.additionalPrizes.length + 4,
          amount: "",
        },
      ],
    }));
  };

  const boxItemWidthStyle = `calc(${
    100 / Math.min(additionalPrizes.length + PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT, 5)
  }% - 20px)`;

  return (
    <>
      <Flex style={{ marginTop: 15, gap: 20 }} width="100%" flexWrap>
        {prizes.map((additionalPrize, index: number) => (
          <BoxItem
            key={index}
            style={{
              flex: `1 0 ${boxItemWidthStyle}`,
              position: "relative",
            }}
          >
            <TheFieldset
              styles={css`
                height: 45px;
                margin: 0;
              `}
              title={`Prize ${index + 1}`}
            >
              <input
                {...numProps}
                placeholder={`Prize ${index + 1}`}
                value={addCommasToNumber(additionalPrize.toString())}
                onChange={(e) => {
                  const input = e.target.value;
                  const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters

                  setPayload((prevPayload: any) => ({
                    ...prevPayload,
                    [`prize${index + 1}`]: +digitsOnly,
                  }));
                }}
              />
            </TheFieldset>
          </BoxItem>
        ))}
        {additionalPrizes
          .sort((a, b) => a.prizeNumber - b.prizeNumber)
          .map(
            (
              { prizeNumber, ...additionalPrize }: { amount: number; prizeNumber: number },
              index: number
            ) => (
              <BoxItem
                key={prizeNumber}
                style={{
                  flex: `1 0 ${boxItemWidthStyle}`,
                  position: "relative",
                }}
              >
                <TheFieldset
                  styles={css`
                    height: 45px;
                    margin: 0;
                  `}
                  title={`Prize ${prizeNumber}`}
                >
                  <input
                    {...numProps}
                    placeholder={`Prize ${prizeNumber}`}
                    value={addCommasToNumber(additionalPrize.amount.toString())}
                    onChange={(e) => {
                      const input = e.target.value;
                      const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters

                      setPayload((prevPayload: any) => {
                        console.log(prizeNumber, prevPayload);

                        const updatedAdditionalPrizes = prevPayload.additionalPrizes.map(
                          (prevAdditionalPrize: any) =>
                            prevAdditionalPrize.prizeNumber === prizeNumber
                              ? { ...prevAdditionalPrize, amount: +digitsOnly }
                              : prevAdditionalPrize
                        );

                        return {
                          ...prevPayload,
                          additionalPrizes: updatedAdditionalPrizes,
                        };
                      });
                      // }
                    }}
                  />
                </TheFieldset>
                {index + 1 === additionalPrizes.length ? (
                  <div
                    style={{ position: "absolute", right: 10, top: 18, cursor: "pointer" }}
                    onClick={() =>
                      setPayload((prev: any) => ({
                        ...prev,
                        additionalPrizes: prev.additionalPrizes.slice(0, -1),
                      }))
                    }
                  >
                    <TheIcon icon="action:remove" size={16} />
                  </div>
                ) : null}
              </BoxItem>
            )
          )}
      </Flex>
      <Flex
        style={{
          border: "1px solid rgba(36, 39, 70, 0.5)",
          borderRadius: 8,
          cursor: "pointer",
          height: 40,
          marginTop: 25,
          marginBottom: 20,
        }}
        width="100%"
        background="rgba(36, 39, 70, 0.5)"
        height={37}
        align="center"
        onClick={addAdditionalPrize}
        justify="center"
      >
        <Image
          style={{ marginRight: 8 }}
          src="/images/icons/add-icon.png"
          alt="plus"
          width={15}
          height={15}
        />
        <ManagePrizesButton>Add Prizes</ManagePrizesButton>
      </Flex>
    </>
  );
};

export default LeaderboardTieredPrizesLayout;
