import { useCallback, useMemo } from "react";
import { MAX_BET_VALUE, MIN_BET_VALUE } from "types/games/constants";

export default function useWrewardsNumbers(): {
  getValueNormalized: (value: number, min?: number, max?: number) => number;
  toFixedWithoutRounding: (value: number) => number;
  toFixedWithoutRoundingString: (value: number) => string;
  formatNumber: (value: number) => string;
} {
  const toFixedWithoutRounding = useMemo(
    () =>
      (value: number): number => {
        let strValue = value.toString();
        const [integerPart, decimalPart] = strValue.split(".");

        if (decimalPart && decimalPart.length > 2) {
          strValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        }

        return parseFloat(strValue);
      },
    []
  );

  const toFixedWithoutRoundingString = useMemo(
    () => (value: number) => {
      let strValue = value.toString();
      const [integerPart, decimalPart] = strValue.split(".");

      if (!decimalPart || decimalPart.length < 2) {
        strValue = `${integerPart}.${decimalPart ? decimalPart.padEnd(2, "0") : "00"}`;
      } else if (decimalPart.length > 2) {
        strValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
      }

      return strValue;
    },
    []
  );

  const getValueNormalized = useCallback(
    (value: number, min: number = MIN_BET_VALUE, max: number = MAX_BET_VALUE): number => {
      const normalizedValue = value < min ? min : value > max ? max : value;
      return toFixedWithoutRounding(normalizedValue);
    },
    [toFixedWithoutRounding]
  );

  const formatNumber = useCallback((value: number | string) => {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  }, []);

  return { getValueNormalized, toFixedWithoutRounding, toFixedWithoutRoundingString, formatNumber };
}
